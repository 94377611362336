import React, {Fragment} from "react"
import { Link, graphql } from "gatsby"

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";

function groupByFirstChar(terms){
  let groups = {}
  terms.map(function(term){
    const firstChar = term.name.charAt(0).toLowerCase();
    if(groups[firstChar]){
      groups[firstChar].push(term);
    }else{
      groups[firstChar] = [term];
    }
  });
  return groups;
}

const GlossaryLinkButtons = (props) => {
  return (
    <div className="glossary-filters my-5">
      <Row className="justify-content-center px-3">
        <ButtonGroup>
          {props.linkNames.map(character => (
            <Col className="px-1">
              <Button outline size="sm" href={'#' + character} color="dark">
                {character.toUpperCase()}
              </Button>
            </Col>
          ))}
        </ButtonGroup>
      </Row>
    </div>
  );
}

const GlossaryTermsGroups = (props) => {
  return (
    <div className="mx-4">
      {props.termGroups.map(([groupCharacter, groupTerms]) => (
        <Row id={groupCharacter}>
          <Col>
            <div className="term-group-title border-bottom border-dark d-inline-block my-4 h2">
              <strong>{groupCharacter.toUpperCase()}</strong>
            </div>
            <div className="term-group">
              {groupTerms.map(term => (
                <div className="mb-3">
                  <div className="term-name text-dark my-2">{term.name}</div>
                  <div className="term-desc my-2" dangerouslySetInnerHTML={{ __html: term.description.value}} />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

const glossaryTermListPage = ({data}) => {
  const terms = data.allTaxonomyTermGlossary.edges.map(({node}) => node);
  const groupedNames = groupByFirstChar(terms);

  return (
    <div> 
      <Container fluid className="mt--6">
        <Row className="justify-content-center">
          <Col className="card-wrapper" lg="8">
            <Card>
              <GlossaryLinkButtons linkNames={Object.keys(groupedNames)} />
              <GlossaryTermsGroups termGroups={Object.entries(groupedNames)} />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export const query = graphql`
  query GlossaryTermListQuery($queriedLanguage: String){
    allTaxonomyTermGlossary(filter: {queriedLanguage: {eq: $queriedLanguage}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          name
          description {
            value
          }
        }
      }
    }
  }
`;

export default glossaryTermListPage;
